import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../carousel";
import { Card } from "../card";
import Autoplay from "embla-carousel-autoplay";

interface Props {
  items: { type: string; title: string; src: string }[];
  size?: "sm" | "md" | "lg" | "full";
}
export default function CustomCarousel({ items, size = "full" }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState<{ type: string; title: string; src: string } | undefined>(undefined);
  const [currentSrc, setCurrentSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (items.length > currentIndex) {
      setCurrentItem(items[currentIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, currentIndex]);

  useEffect(() => {
    setCurrentSrc(currentItem?.src);
  }, [currentItem]);

  function nextImage() {
    if (items.length > currentIndex + 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  }

  function previousImage() {
    if (currentIndex === 0) {
      setCurrentIndex(items.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  }

  return (
    <Fragment>
      <div className="relative mx-auto w-full">
        <CarouselItems items={items} size={size} />
      </div>
    </Fragment>
  );
}

export function CarouselItems({ items, size }: Props) {
  return (
    <Carousel
      // plugins={[
      //   Autoplay({
      //     delay: 5000,
      //   }),
      // ]}
      className={clsx("mx-auto w-full", size === "sm" && "max-w-xs", size === "md" && "max-w-md", size === "lg" && "max-w-lg", size === "full" && "max-w-full")}
    >
      <CarouselContent>
        {items.map((item, index) => (
          <CarouselItem key={index}>
            <div className="p-1">
              <Card className="overflow-hidden rounded-lg">
                {/* <span className="text-4xl font-semibold">{index + 1}</span> */}
                {item.type === "image" && (
                  <div className={clsx(size === "sm" && "h-48", size === "md" && "h-64", size === "lg" && "h-96", size === "full" && "h-96")}>
                    <img key={item.src} loading="lazy" className={clsx("min-h-full w-full object-cover md:h-auto")} src={item.src} alt={item.title} />
                  </div>
                )}
                {item?.type === "video" && (
                  <div
                    className={clsx(
                      "h-96 min-h-full w-full object-cover ",
                      size === "sm" && "h-48",
                      size === "md" && "h-64",
                      size === "lg" && "h-96",
                      size === "full" && "h-96"
                    )}
                  >
                    <iframe
                      key={item.src}
                      src={item.src}
                      title={item?.title ?? ""}
                      loading="lazy"
                      className="h-full w-full"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                )}
              </Card>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
}
